var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollTop),expression:"scrollTop",arg:"tap"}],staticClass:"burger top",class:{ hidden: _vm.isHiddenT },attrs:{"id":"burger-top"}},[_c('img',{attrs:{"src":require("../assets/arrow.svg"),"alt":""}})]),(!_vm.isMobile())?_c('div',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.showNav),expression:"showNav",arg:"tap"}],staticClass:"burger",class:{ hidden: _vm.isHiddenB, out: _vm.isOutB }},[_c('img',{attrs:{"src":require("../assets/burger.svg"),"alt":""}})]):_vm._e(),(_vm.isMobile())?_c('div',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.toggleBurger),expression:"toggleBurger",arg:"tap"}],staticClass:"burger",class:{ actv1: _vm.burgerActv1, actv2: _vm.burgerActv2 },attrs:{"id":"burger-menu"}},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/burger.svg"),"alt":""}}),_c('div',{staticClass:"menu"},[_c('div',[_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#home')),expression:"scrollToSection('#home')",arg:"tap"}],staticClass:"nav-link",class:{
            actv: _vm.activePage == 'home'
          }},[_vm._v(" accueil ")]),_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#metier')),expression:"scrollToSection('#metier')",arg:"tap"}],staticClass:"nav-link",class:{
            actv: _vm.activePage == 'metier'
          }},[_vm._v(" métier ")]),_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#equipe')),expression:"scrollToSection('#equipe')",arg:"tap"}],staticClass:"nav-link",class:{
            actv: _vm.activePage == 'equipe'
          }},[_vm._v(" équipe ")]),_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#valeur')),expression:"scrollToSection('#valeur')",arg:"tap"}],staticClass:"nav-link",class:{
            actv: _vm.activePage == 'valeur'
          }},[_vm._v(" valeurs ajoutées ")]),_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#contact')),expression:"scrollToSection('#contact')",arg:"tap"}],staticClass:"nav-link",class:{
            actv: _vm.activePage == 'contact'
          }},[_vm._v(" contact ")])])])]):_vm._e(),(!_vm.isMobile())?_c('div',{staticClass:"navbar",class:{ hidden: _vm.isHidden, out: _vm.isOut }},[_c('div',{staticClass:"links flex-row"},[_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#home')),expression:"scrollToSection('#home')",arg:"tap"}],staticClass:"nav-link",class:{
          actv: _vm.activePage == 'home',
          actvHome: _vm.activePage == 'home',
        }},[_vm._v(" accueil ")]),_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#metier')),expression:"scrollToSection('#metier')",arg:"tap"}],staticClass:"nav-link",class:{
          actv: _vm.activePage == 'metier',
          actvHome: _vm.activePage == 'home',
        }},[_vm._v(" métier ")]),_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#equipe')),expression:"scrollToSection('#equipe')",arg:"tap"}],staticClass:"nav-link",class:{
          actv: _vm.activePage == 'equipe',
          actvHome: _vm.activePage == 'home',
        }},[_vm._v(" équipe ")]),_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#valeur')),expression:"scrollToSection('#valeur')",arg:"tap"}],staticClass:"nav-link",class:{
          actv: _vm.activePage == 'valeur',
          actvHome: _vm.activePage == 'home',
        }},[_vm._v(" valeurs ajoutées ")]),_c('p',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.scrollToSection('#contact')),expression:"scrollToSection('#contact')",arg:"tap"}],staticClass:"nav-link",class:{
          actv: _vm.activePage == 'contact',
          actvHome: _vm.activePage == 'home',
        }},[_vm._v(" contact ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }