<template>
  <div class="page">
    <div id="home" class="section flex-col">
      <div class="main-titles" :class="{ mobile: isMobile() }">
        <p v-if="!isMobile()" class="title">Guyenne Capital</p>
        <p v-if="isMobile()" class="title">Guyenne<br>Capital</p>
        <p class="subtitle">Opérations Complexes et Retournement</p>
      </div>
      <div class="rect"></div>
    </div>
    <div id="metier" class="section flex-col">
      <p class="title">Notre métier</p>
      <Metier />
    </div>
    <div id="equipe" class="section flex-col">
      <p class="title">Notre equipe</p>
      <Equipe />
    </div>
    <div id="valeur" class="section flex-col">
      <p class="title">Nos valeurs ajoutées</p>
      <Valeur />
    </div>
    <div id="contact" class="section flex-col">
      <p class="title">Nous contacter</p>
      <Contact />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { isMobile } from 'mobile-device-detect';
import Metier from "@/components/Metier.vue";
import Valeur from "@/components/Valeur.vue";
import Equipe from "@/components/Equipe.vue";
import Contact from "@/components/Contact.vue";

export default {
  name: "Home",
  components: {
    Metier,
    Valeur,
    Equipe,
    Contact
  },
  methods: {
    isMobile() {
      console.log(isMobile);
      return isMobile;
    },
  },
};
</script>

<style lang="scss" scoped>
#home {
  position: relative;
  width: 100vw;
  margin-left: -5vw;
  background-image: url("../assets/bg-dark.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  height: calc(100vh + 100px);
  .rect {
    position: absolute;
    left: 0px;
    bottom: -1px;
    height: 100px;
    width: 100vw;
    background-color: white;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
  }
  .main-titles {
    margin-top: -380px;
    p.title {
      color: white;
      font-size: 7rem;
      margin: 0px;
    }
    p.subtitle {
      color: white;
      margin: 0px;
    }
    &.mobile {
      margin-top: -350px;
      p.title {
        font-size: 4rem;
      }
      p.subtitle {
        font-size: 0.8rem;
        width: 80vw;
        margin: auto;
        margin-top: 20px;
      }
    }
  }
}

.page {
  width: 90vw;
  margin: auto;
  .section {
    .content {
      max-width: 1200px;
    }
    text-align: left;
    padding-top: 100px;
    padding-bottom: 100px;
    font-family: "Open Sans", sans-serif;
  }
}
</style>