import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto';
import Vue2TouchEvents from 'vue2-touch-events'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toastOptions = {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 5,
  newestOnTop: true
};

Vue.use(Toast, toastOptions);
 
Vue.use(Vue2TouchEvents, {
  touchClass: 'tapped',
})
Vue.use(VueScrollTo, {
     container: "body",
     duration: 800,
     easing: [0.75, 0, 0.45, 1],
     offset: 0,
     force: true,
     cancelable: false,
     onStart: () => {console.log('SCROLL START');},
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
