<template>
  <div class="content">
    <ol>
      <li>
        Expérience dans le retournement opérationnel d’entreprises (en qualité
        de Chief Restructuring Officer, de Manager de Transition, etc..) en
        double commande avec les équipes de direction (ancienne ou nouvelle),
        <br /><br />
      </li>
      <li>
        Expérience dans la mise en place de schémas d’interventions innovants et
        sur mesure, que ce soit en amont ou en aval des situations de crise
        (utilisations d’outils juridiques et financiers adaptés aux situations
        de crise),<br /><br />
      </li>
      <li>
        Maîtrise des situations de crise (tant sur le plan de la communication,
        que sur le plan social, juridique ou financier). Une entreprise en crise
        ne se gère pas comme une entreprise en rythme de croisière.<br /><br />
      </li>
      <li>
        Capacité d’intervention : Rapidité, flexibilité grâce à des équipes
        réduites et spécialisées dans ce type de dossiers.<br /><br />
      </li>
      <li>
        Réseau de partenaires adaptés (auditeurs, avocats, ...)<br /><br />
      </li>
    </ol>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.content {
  ol {
    list-style-type: "●   ";
    padding-left: 0px;
    li {
      display: list-item;
      font-size: 1.1rem;
      margin-left: 25px;
    }
  }
  .container-1-list {
    margin-left: 3rem;
  }
}
</style>