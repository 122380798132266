<template>
  <div class="content">
    <ul>
      <li>
        <span class="bolder">GUYENNE CAPITAL est une société industrielle d'investissement
          reprenant totalement ou partiellement des sociétés en difficultés
          temporaires ayant une capacité de retournement.</span><br /><br />
        Le métier de GUYENNE CAPITAL repose sur un constat:<br /><br />
        Chaque année les tribunaux de commerce enregistrent un nombre important
        de défaillances d'entreprises. Certaines d'entre elles sont de taille
        importante avec les conséquences économiques et sociales que chacun
        connait.<br /><br />
        La réforme de 2005 du droit des procédures collectives et ses évolutions
        successives a doté la France d'un outil juridique efficace en matière de
        prévention et de sauvetage des sociétés en difficultés.<br />
        Reste la question de leur retournement tant en matière opérationnelle
        (méthodes, intervenants spécialistes) que financière (opérateurs,
        financements) : sur ce point il convient d'aborder la question avec
        lucidité.<br /><br />
        Retourner c'est réussir là où d'autres ont échoué, c'est donc faire ce
        que d'autres n'ont pas pu, pas su ou pas voulu faire avant.<br /><br />
        Il s'agit donc d'appliquer des méthodes spécifiques: on ne gère pas une
        entreprise en difficulté comme une entreprise qui va bien!<br /><br />
        Le Restructuring est un savoir-faire et un métier, c'est donc l'affaire
        de spécialistes. <br /><br /><br /><br />
      </li>
    </ul>

    <span class="bolder">La stratégie d’investissement de Guyenne Capital repose sur:</span
    ><br /><br />

    <ul>
      <div class="container-1-list" :class="{ mobile: isMobile() }">
        <li>
          <span class="bordeaux"
            ><b>Une segmentation des cibles... :</b></span
          >
          <br /><br />
          <div class="container-2-list">
            • PME/ETI comprises entre 10M€ et 100M€ de CA (jusqu’à 500M€ en
            co-investissement)<br />
            • Sociétés, Groupes, filiales de Groupes traversant une phase de
            turbululance ou faisant face à des enjeux de transformations
            complexes<br /><br />
          </div>
        </li>
        <li>
          <span class="bordeaux"
            ><b
              >Un ciblage des interventions sur des secteurs d'activité
                identifiés, connus et maîtrisés :</b
            ></span
          >
          <br /><br />
          <div class="container-2-list">
            • Nouvelles Technologies (WebTech, Software, etc...) et télécoms<br />
            • Tourisme<br />
            • Services financiers<br />
            • Industries traditionnelles (Biens d’équipement, agroalimentaire,
            chimie-pharma, matériaux, etc)<br />
            • Ingénierie et services complexes <br />
            • Environnement (énergie, eau, déchets) <br />
            • BTP <br />
            • Marketing direct, communication <br />
            • Chaîne graphique <br />
            • Distribution spécialisée <br /><br />
          </div>
        </li>
        <li>
          <span class="bordeaux"
            ><b>Une politique d'investissement claire :</b></span
          >
          <br /><br />
          <div class="container-2-list">
            • Apport en Equity<br />
            • Intervention au capital en qualité d’actionnaire<br />
            • Contrôle de la cible pendant toute la durée de l’intervention de
            retournement (majorité ou minorité avec droits de votes doubles
            donnant la majorité de contrôle)<br />
            • Possibilité d’intervention pour le compte de grands groupes en
            portage de titres, en commandite, etc... lorsque l’objectif est de
            détourer/déconsolider voire préparer l’intégration dans un groupe
            d’une branche d’activité ou une filiale afin de permettre le
            retournement de cette dernière ainsi que pour des opération de
            spin-off<br /><br />
          </div>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
export default {
    methods: {
        isMobile() {
            return isMobile;
        }
    }
};
</script>

<style lang="scss" scoped>
.content {
  ul {
    list-style-type: none;
    padding-left: 0px;
    li {
      display: list-item;
    }
  }
  .container-1-list {
      margin-left: 3rem;
      &.mobile {
          margin-left: 0px;
      }
  }
}
</style>