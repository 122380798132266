<template>
  <div class="content" :class="{ mobile: isMobile() }">
    <p class="subtitle">Laissez-nous un message<br>pour être recontacté</p>
    <div class="form">
      <div class="name-w">
        <input
          v-model="prenom"
          class="input"
          placeholder="PRENOM"
          type="text"
        />
        <input v-model="nom" class="input" placeholder="NOM" type="text" />
      </div>
      <input v-model="email" class="input" placeholder="EMAIL" type="email" />
      <!-- <input placeholder="MESSAGE" type="textarea" rows="8"/> -->
      <textarea
        v-model="msg"
        class="area"
        placeholder="MESSAGE"
        rows="10"
      ></textarea>
      <button v-touch="sendMessage" class="btn">ENVOYER</button>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      prenom: "",
      nom: "",
      email: "",
      msg: "",
    };
  },
  methods: {
    isMobile() {
      return isMobile;
    },
    sendMessage() {
      console.log(this.prenom, this.nom, this.email, this.msg);
      if (!this.prenom) {
        this.toast('Veuillez renseigner votre prénom');
      }
      if (!this.nom) {
        this.toast('Veuillez renseigner votre nom')
      }
      if (!this.email) {
        this.toast('Veuillez renseigner votre email')
      }
      if (!this.msg) {
        this.toast('Veuillez renseigner un message')
      }
      if (this.prenom && this.nom && this.email && this.msg) {
        this.toastS('Message envoyé');
      }
    },
    toast(text) {
      this.$toast.error(text, {
        position: "bottom-center",
        timeout: 4000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    toastS(text) {
      this.$toast.success(text, {
        position: "bottom-center",
        timeout: 4000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90vw;
  max-width: 700px;
  padding-bottom: 300px;
  &.mobile {
    padding-bottom: 100px;
  }
  p.subtitle {
    margin-bottom: 100px;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    width: 90vw;
    max-width: 700px;
  }
  .name-w {
    display: flex;
    justify-content: space-between;
    input {
      width: calc(50% - 35px);
    }
  }
  input,
  textarea {
    border: none;
    border-radius: 25px;
    padding: 0px;
    padding-left: 30px;
    height: 50px;
    background-color: rgb(245, 245, 245);
    margin-bottom: 20px;

    &.area {
      height: auto;
      padding-top: 20px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>