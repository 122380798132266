<template>
  <div class="w">
    <div
      id="burger-top"
      v-touch:tap="scrollTop"
      class="burger top"
      :class="{ hidden: isHiddenT }"
    >
      <img src="../assets/arrow.svg" alt="" />
    </div>
    <div
      v-if="!isMobile()"
      v-touch:tap="showNav"
      class="burger"
      :class="{ hidden: isHiddenB, out: isOutB }"
    >
      <img src="../assets/burger.svg" alt="" />
    </div>
    <div
      id="burger-menu"
      v-if="isMobile()"
      v-touch:tap="toggleBurger"
      class="burger"
      :class="{ actv1: burgerActv1, actv2: burgerActv2 }"
    >
      <img class="img" src="../assets/burger.svg" alt="" />
      <div class="menu">
        <div>
          <p
            v-touch:tap="scrollToSection('#home')"
            :class="{
              actv: activePage == 'home'
            }"
            class="nav-link"
          >
            accueil
          </p>
          <p
            v-touch:tap="scrollToSection('#metier')"
            :class="{
              actv: activePage == 'metier'
            }"
            class="nav-link"
          >
            métier
          </p>
          <p
            v-touch:tap="scrollToSection('#equipe')"
            :class="{
              actv: activePage == 'equipe'
            }"
            class="nav-link"
          >
            équipe
          </p>
          <p
            v-touch:tap="scrollToSection('#valeur')"
            :class="{
              actv: activePage == 'valeur'
            }"
            class="nav-link"
          >
            valeurs ajoutées
          </p>
          <p
            v-touch:tap="scrollToSection('#contact')"
            :class="{
              actv: activePage == 'contact'
            }"
            class="nav-link"
          >
            contact
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!isMobile()"
      class="navbar"
      :class="{ hidden: isHidden, out: isOut }"
    >
      <!-- <div class="brand flex-row">
        <a href="https://www.outofmedina.com">
          <img src="../assets/logo.png" alt="Brand Logo" />
        </a>
      </div> -->
      <div class="links flex-row">
        <p
          v-touch:tap="scrollToSection('#home')"
          :class="{
            actv: activePage == 'home',
            actvHome: activePage == 'home',
          }"
          class="nav-link"
        >
          accueil
        </p>
        <p
          v-touch:tap="scrollToSection('#metier')"
          :class="{
            actv: activePage == 'metier',
            actvHome: activePage == 'home',
          }"
          class="nav-link"
        >
          métier
        </p>
        <p
          v-touch:tap="scrollToSection('#equipe')"
          :class="{
            actv: activePage == 'equipe',
            actvHome: activePage == 'home',
          }"
          class="nav-link"
        >
          équipe
        </p>
        <p
          v-touch:tap="scrollToSection('#valeur')"
          :class="{
            actv: activePage == 'valeur',
            actvHome: activePage == 'home',
          }"
          class="nav-link"
        >
          valeurs ajoutées
        </p>
        <p
          v-touch:tap="scrollToSection('#contact')"
          :class="{
            actv: activePage == 'contact',
            actvHome: activePage == 'home',
          }"
          class="nav-link"
        >
          contact
        </p>
      </div>
      <!-- <div class="end flex-row">
        <button class="btn"><p>réserver</p></button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      lastPos: null,
      scrolling: false,
      out: true,
      aminDur: 510,
      animDel: 0,
      isHidden: false,
      isOut: false,
      isHiddenB: true,
      isOutB: false,
      isHiddenT: true,
      activePage: "home",
      burgerActv1: false,
      burgerActv2: false,
      burgerOpened: false,
      aa: 0,
      bb: 0,
      cc: 0,
      dd: 0,
      ee: 0,
    };
  },
  methods: {
    scrollToSection(el) {
      return () => {
        console.log(el);
        this.$scrollTo(el);
      };
    },
    isMobile() {
      return isMobile;
    },
    toggleBurger() {
      if (!this.burgerOpened) {
        this.burgerOpened = true;
        console.log("open");
        this.burgerActv1 = true;
        setTimeout(() => {
          this.burgerActv2 = true;
        }, 10);
      } else {
        this.burgerOpened = false;
        console.log("close");
        this.burgerActv2 = false;
        setTimeout(() => {
          this.burgerActv1 = false;
        }, 610);
      }
    },
    handleScroll() {
      const s = window.scrollY;
      const ofset = 200;
      if (s >= 0 && s < this.bb - ofset) {
        console.log("a active");
        this.activePage = "home";
      } else if (s >= this.bb - ofset && s < this.cc - ofset) {
        console.log("b active");
        this.activePage = "metier";
      } else if (s >= this.cc - ofset && s < this.dd - ofset) {
        console.log("c active");
        this.activePage = "equipe";
      } else if (s >= this.dd - ofset && s < this.ee - ofset) {
        console.log("d active");
        this.activePage = "valeur";
      } else if (s >= this.ee - ofset) {
        console.log("e active");
        this.activePage = "contact";
      }

      if (window.scrollY < 120) {
        this.isHiddenT = true;
      } else if (window.scrollY >= 120) {
        this.isHiddenT = false;
      }
    },
    scrollTop() {
      console.log("scrlltop");
      this.$scrollTo("body");
    },
    hideNav() {
      console.log("hideNav");
      this.out = false;
      this.isHiddenB = false;
      this.isHidden = true;
    },
    showNav() {
      console.log("showNav");
      this.out = true;
      this.isHiddenB = true;
      this.isHidden = false;
    },
  },
  mounted() {
    this.aa = document.getElementById("home").offsetTop;
    this.bb = document.getElementById("metier").offsetTop;
    this.cc = document.getElementById("equipe").offsetTop;
    this.dd = document.getElementById("valeur").offsetTop;
    this.ee = document.getElementById("contact").offsetTop;
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>


<style lang="scss">
$primary: #efdbc8;
$animFunc: cubic-bezier(0.92, 0, 0.13, 1.01);
// $animFunc: cubic-bezier(0.61, 0.01, 0.41, 1.01);
// $animFunc: cubic-bezier(.27,.1,.29,.98);
$animDur: 600ms;
$animDel: 0ms;

.burger {
  z-index: 99;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 30px;
  right: 30px;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: black;
  transition: all $animDur;
  transition-timing-function: $animFunc;
  // &.tapped {
  //   transform: translateX(0px) translateY(0px) scale(1.1);
  // }
  &.top {
    top: initial;
    bottom: 30px;
    &.hidden {
      transform: translateX(0px) translateY(0px) scale(0);
    }
  }
  &.hidden {
    transform: translateX(0px) translateY(0px) scale(0);
  }
  .menu {
    display: none;
    position: fixed;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all $animDur;
    transition-timing-function: $animFunc;
    .nav-link {
      text-transform: uppercase;
      font-size: 19px;
      cursor: pointer;
      position: relative;
      color: white;
      transition: all 200ms;
      &:after {
        content: "";
        position: absolute;
        height: 3px;
        background-color: #ffffff;
        left: 30%;
        width: 0px;
        bottom: -15px;
        border-radius: 50px;
        opacity: 0;
        transition: all 300ms;
      }
      &.actv {
        font-weight: 900;
        color: #ffffff;
        &:after {
          opacity: 0;
          width: calc(40%);
        }
      }
      &.actvHome {
        font-weight: 900;
        color: #ffffff;
        &:after {
          background-color: #ffffff;
        }
      }
    }
  }
  &.actv1 {
    .menu {
      display: flex;
    }
    img.img {
      opacity: 0;
    }
  }
  &.actv2 {
    top: -20vh;
    right: -50vh;
    height: 150vh;
    width: 150vh;
    border-radius: 10000px;
    .menu {
      opacity: 1;
    }
    img.img {
      display: none;
    }
  }
}

.navbar {
  z-index: 99;
  position: fixed;
  top: 0px;
  left: 5vw;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  opacity: 1;
  transition: all $animDur;
  transition-timing-function: $animFunc;
  &.hidden {
    transform: translateY(-120px);
    opacity: 0;
  }
  &.out {
    .brand,
    .links,
    .end {
      display: none;
    }
  }
  .brand {
    width: 140px;
    justify-content: flex-start;
    img {
      height: 60px;
    }
  }
  .links {
    justify-content: space-between;
    width: 50%;
    .nav-link {
      text-transform: uppercase;
      font-size: 19px;
      cursor: pointer;
      position: relative;
      color: black;
      transition: all 200ms;
      &:after {
        content: "";
        position: absolute;
        height: 3px;
        background-color: #022d50;
        left: -15px;
        // right: -15px;
        width: 0px;
        bottom: -15px;
        border-radius: 50px;
        transform: translateY(0px);
        opacity: 0;
        transition: all 300ms;
      }
      &.actv {
        font-weight: 900;
        color: #022d50;
        &:after {
          opacity: 1;
          transform: translateY(0px);
          width: calc(100% + 30px);
        }
      }
      &.actvHome {
        font-weight: 900;
        color: #ffffff;
        &:after {
          background-color: #ffffff;
        }
      }
    }
  }
  .end {
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 120px;
      border-radius: 5px;
      border: none;
      background-color: black;
      p {
        font-family: brandon-grotesque, sans-serif;
        text-transform: uppercase;
        font-size: 19px;
        font-weight: bold;
        color: $primary;
      }
    }
  }
}
</style>