<template>
  <div class="content" :class="{ mobile: isMobile() }">
    <div id="patrick" class="card">
      <div v-touch:tap="closeMore(this)" class="close">
        <img src="../assets/close.svg" alt="" />
      </div>
      <div class="infos">
        <img src="@/assets/patrick.jpg" alt="Photo Patrick Grillat" />
        <p class="name">patrick grillat</p>
        <p class="origin">Founding Partner</p>
        <p class="position bordeaux">Chairman</p>
        <button v-touch:tap="more(this)" class="btn">voir plus</button>
      </div>
      <div class="desc-w">
        <p class="desc">
          <b>Patrick Grillat</b> (Ingénieur énergéticien + économie
          d’entreprises + droit des affaires et des entreprises en difficultés)
          est un professionnel reconnu du restructuring. <br /><br />
        </p>
        <p>
          <b>En France</b>,<br /><br />
          Après 20 ans dans l’industrie (énergétique – pétrole/gaz/nucléaire ;
          construction navale ; ferroviaire ; télécoms) dans des fonctions de
          management opérationnel et général au sein de grands groupes et ETI,
          il a dirigé pendant 15 ans un cabinet de conseil en Restructuring et
          M&A de crise. <br /><br />
          Seul (CEO) ou aux côtés des dirigeants historiques (CRO), il a, à ce
          titre, piloté avec succès de nombreuses opérations de retournement
          pour le compte de grands groupes, de PME/ETI et d’actionnaires privés
          ou institutionnels. <br /><br />
          Il a une expérience reconnue des procédures de prévention et
          collectives et intervient régulièrement en audiences devant les
          tribunaux de commerce. <br /><br />
        </p>
        <p>
          <b>A l’international</b>, Il a dirigé des entreprises au Royaume Uni
          (où il a passé une grande partie de sa vie) et aux Etats Unis.
          <br /><br />
          Il a piloté des grands projets d’ingénierie et œuvré à la négociation
          de contrats en Europe (UK ; CE ; PECO), en Afrique, au Moyen Orient,
          en Russie et en Amérique du Nord aux côtés de partenaires industriels
          et exploitants. <br /><br />
        </p>
      </div>
    </div>
    <div id="olivier" class="card">
      <div v-touch:tap="closeMore(this)" class="close">
        <img src="../assets/close.svg" alt="" />
      </div>
      <div class="infos">
        <img src="@/assets/olivier.jpg" alt="Photo Olivier Robert" />
        <p class="name">olivier robert</p>
        <p class="origin">Founding Partner</p>
        <p class="position bordeaux">CEO</p>
        <button v-touch:tap="more(this)" class="btn">voir plus</button>
      </div>
      <div class="desc-w">
        <p class="desc">
          Doté d'une double formation en finance d’entreprise et en droit des
          affaires, Olivier possède une solide expérience des opérations de
          fusions & acquisitions, levées de fonds et restructuring d'entreprises
          en difficultés pour le compte d’investisseurs financiers, de groupes
          industriels et de services.<br /><br />Analyst, Manager, puis Partner
          d’un cabinet de Conseils Financiers et Opérationnels spécialisés en
          restructuration d’entreprises, management de crise et
          fusions-acquisitions complexes, il dirige aujourd'hui les opérations
          de Guyenne Capital qu'il a co-fondé avec Patrick Grillat et Julie
          Berda.<br /><br />Olivier est diplômé de l’ISC (Institut Supérieur de
          Commerce de Paris) - Master 2 Finance.
        </p>
      </div>
    </div>
    <div id="julie" class="card">
      <div v-touch:tap="closeMore(this)" class="close">
        <img src="../assets/close.svg" alt="" />
      </div>
      <div class="infos">
        <img src="@/assets/julie.jpg" alt="Photo Olivier Robert" />
        <p class="name">julie berda</p>
        <p class="origin">Founding Partner</p>
        <p class="position bordeaux"><br /></p>
        <button v-touch:tap="more(this)" class="btn">voir plus</button>
      </div>
      <div class="desc-w">
        <p class="desc">
          Dotée d'une triple formation en Business Administration, Analyse
          Financière, et Management des Risques, Julie possède une solide
          expérience de la restructuration d'entreprises en difficultés.
          <br /><br />Analyst puis Chief Analyst d’un cabinet de conseils
          financiers et opérationnels spécialisés en restructuration
          d’entreprises, management de crise et fusions-acquisitions complexes,
          elle conseille aujourd'hui le board de Guyenne Capital, elle est plus
          particulièrement en charge du contrôle des risques et des questions
          d'assurances. <br /><br />Julie est diplômée de KEDGE BUSINESS SCHOOL
          (Master Spécialisé en Management des Risques), de l’INSEEC (Master 2
          d’Analyse Financière Internationale) et de l’ESG (Bachelor in Business
          Administration).
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      popUpOpened: false,
      openedEl: null,
      dummy: null,
    };
  },
  methods: {
    isMobile() {
      return isMobile;
    },
    closeMore() {
      return (event) => {
        console.log(event);
        console.log(this.openedEl);
        console.log(this.openedEl.btn);
        console.log(this.dummy);
        this.openedEl.el.style.top = `${this.openedEl.top}px`;
        this.openedEl.el.style.left = `${this.openedEl.left}px`;
        this.openedEl.el.style.width = `${this.openedEl.w - 60}px`;
        this.openedEl.el.style.height = `${this.openedEl.h - 60}px`;
        this.openedEl.el.style.paddingTop = null;
        this.openedEl.el.style.paddingBottom = null;
        this.openedEl.el.style.maxWidth = null;
        this.openedEl.el.style.boxShadow = null;
        this.openedEl.close.style.opacity = null;
        this.openedEl.el.children[2].classList.remove("actv");
        this.openedEl.el.querySelector(".infos").style.height = null;
        if (this.isMobile()) {
          this.openedEl.btn.style.display = null;
          document.getElementById("burger-menu").style.display = null;
          document.getElementById("burger-top").style.display = null;
        }
        setTimeout(() => {
          this.openedEl.el.style.position = null;
          this.openedEl.el.style.top = null;
          this.openedEl.el.style.left = null;
          this.openedEl.el.style.width = null;
          this.openedEl.el.style.height = null;
          this.openedEl.el.style.transition = null;

          this.openedEl.el.style.transitionTimingFunction = null;
          this.openedEl.el.classList.remove("actv");
          this.openedEl.close.style.display = null;
          document.querySelector("body").style.overflow = null;
          this.dummy.remove();
        }, 1010);
        setTimeout(() => {
          this.openedEl.btn.style.opacity = null;
          this.popUpOpened = false;
        }, 10);
      };
    },
    more() {
      return (event) => {
        if (!this.popUpOpened) {
          document.querySelector("body").style.overflow = `hidden`;
          this.popUpOpened = true;
          let card = {};
          let btn = event.target;
          card.el = event.target.parentNode.parentNode;
          card.btn = btn;
          let close = card.el.children[0];
          card.close = close;
          this.openedEl = card;
          console.log(close);
          card.id = card.el.id;
          card.w = card.el.offsetWidth;
          card.h = card.el.offsetHeight;
          card.top = card.el.getBoundingClientRect().top;
          card.left = card.el.getBoundingClientRect().left;
          console.log(card);

          card.el.style.top = `${card.top}px`;
          card.el.style.left = `${card.left}px`;
          card.el.style.width = `${card.w - 60}px`;
          card.el.style.height = `${card.h}px`;
          card.el.style.paddingTop = `${30}px`;
          card.el.style.paddingBottom = `${30}px`;
          // card.el.style.padding = `30px`;
          card.el.style.position = `fixed`;
          setTimeout(() => {
            card.el.style.transition = `all 1000ms`;
            card.el.style.transitionTimingFunction =
              "cubic-bezier(.75,.01,.45,1)";
          }, 10);
          var parent = card.el.parentNode;
          var dummy = document.createElement("div");
          dummy.style.width = `${card.w}px`;
          dummy.style.height = `${card.h - 60}px`;
          if (!this.isMobile()) {
            dummy.style.padding = `${30}px`;
          } else {
            dummy.style.paddingTop = `30px`;
            dummy.style.paddingBottom = `30px`;
          }
          this.dummy = dummy;
          parent.insertBefore(dummy, card.el);
          setTimeout(() => {
            if (!this.isMobile()) {
              card.el.classList.add("actv");
              card.el.style.top = `15vh`;
              card.el.style.left = `10vw`;
              card.el.style.width = `calc(80vw - 60px)`;
              card.el.style.height = `70vh`;
              card.el.style.maxWidth = `100vw`;
              card.el.style.boxShadow = `rgba(149, 157, 165, 0.2) 0px 8px 24px`;
              card.el.querySelector(".infos").style.height = `66%`;
              btn.style.opacity = `0`;
            } else {
              card.el.classList.add("actv");
              card.el.style.top = `0px`;
              card.el.style.left = `0px`;
              card.el.style.width = `calc(100vw - 60px)`;
              card.el.style.height = `calc(100% - 60px)`;
              card.el.style.maxWidth = `100vw`;
              card.el.style.boxShadow = `rgba(149, 157, 165, 0.2) 0px 8px 24px`;
              card.el.querySelector(".infos").style.height = `66%`;
              btn.style.opacity = `0`;
              btn.style.display = `none`;
              document.getElementById("burger-menu").style.display = "none";
              document.getElementById("burger-top").style.display = "none";
            }

            // btn.style.maxHeight = `0px`;
            close.style.display = `block`;
            setTimeout(() => {
              close.style.opacity = `1`;
            }, 10);
            setTimeout(() => {
              card.el.children[2].classList.add("actv");
            }, 10);
          }, 10);
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  &.mobile {
    flex-direction: column;
    .card {
      flex-direction: column;
      .close {
        top: 15px;
        right: 15px;
      }
      .infos {
        height: 100%;
        transition: all 1000ms;
        transition-timing-function: cubic-bezier(0.75, 0.01, 0.45, 1);
      }
      .desc-w {
        overflow: hidden;
        width: 100%;
        height: 0px;
        text-align: left;
        &.actv {
          position: relative;
          overflow-y: scroll;
          width: 100%;
          margin-left: 0px;
          margin-top: 0px;
          top: 0px;

          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgb(184, 184, 184);
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }
    }
  }
  .card {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    max-width: 400px;
    padding: 30px;
    border-radius: 40px;
    font-family: "Open Sans", sans-serif;
    .infos {
      height: 100%;
      transition: all 1000ms;
      transition-timing-function: cubic-bezier(0.75, 0.01, 0.45, 1);
    }
    .desc-w {
      overflow: hidden;
      width: 0px;
      height: 200px;
      text-align: left;
      transition: all 1000ms;
      transition-timing-function: cubic-bezier(0.75, 0.01, 0.45, 1);
      &.actv {
        overflow-y: auto;
        width: 50%;
        height: 66%;
        margin-left: 80px;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgb(184, 184, 184);
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }
    &.actv {
      background-color: white;
    }
    .close {
      display: none;
      opacity: 0;
      position: absolute;
      top: 30px;
      right: 30px;
      height: 40px;
      width: 40px;
      border-radius: 100px;
      background-color: black;
      transition: all 1000ms;
      transition-timing-function: cubic-bezier(0.75, 0.01, 0.45, 1);
      cursor: pointer;
    }
    p {
      margin: 0px;
      margin-bottom: 10px;
    }
    p.name {
      font-size: 2rem;
      font-weight: 900;
      font-family: brandon-grotesque, sans-serif;
      text-transform: uppercase;
      margin-bottom: 0px;
    }
    p.origin {
      text-transform: capitalize;
      font-style: italic;
    }
    p.position {
      font-size: 1.5rem;
      font-weight: 600;
      font-family: brandon-grotesque, sans-serif;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    img {
      max-width: 400px;
      width: 100%;
      border-radius: 20px;
      margin-bottom: 30px;
    }
  }
}
</style>